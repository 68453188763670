<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>第六届“创青春”中国青年创新创业大赛（互联网组）全国赛圆满收官</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>第六届“创青春”中国青年创新创业大赛（互联网组）全国赛圆满收官</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/10/24</span>
          <span class="time-title">中国青年创新创业大赛</span>
          <span class="gold">金奖
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">由共青团中央、中央网信办、浙江省人民政府主办的第六届“创青春”中国青年创新创业大赛（互联网组）全国赛于10月23日在杭州“梦想小镇”圆满收官。经过激烈角逐，比赛最终产生总冠军1个，金奖9个，银奖20个，铜奖70个。</p>
        <div class="fourth">
          <div class="fourth-img1"><img src="@/assets/img/chuangke1.jpg" alt=""></div>
          <div class="fourth-img2"><img src="@/assets/img/chuangke2.jpg" alt=""></div>
        </div>
        <!-- <p class="fourth-p2">汕头市人民政府副市长林晓湧接见获奖企业代表</p> -->
        <p class="fourth-p3" style="margin-top:2rem">“创青春”中国青年创新创业大赛（互联网组）是“创青春”中国青年创新创业大赛的分组赛事之一。自4月启动以来，通过社会报名和组织推荐，收到报名参赛项目7105个，内容涉及移动互联网、智慧城市、数字经济、互联网技术与应用等多个领域。经过初赛、复赛，有100件作品进入全国赛决赛。10月21日至23日，大赛以秘密答辩、公开答辩形式在杭州进行晋级赛比拼。</p>
        <!-- <p class="fourth-p3">投资意向签约仪式中，部分投资方代表和项目方代表上台进行投资意向签约，他们将会在赛后进行持续的沟通和交流，同时组委会也将建立相应的平台和渠道帮助更多的项目、技术、人才和资本的互联互通。</p> -->
        <div class="firth">
          <div><img src="@/assets/img/chuangke3.jpg" alt=""></div>
        </div>
        <p class="fourth-center fourth-p2">团中央书记处书记傅振邦为获奖者颁奖</p>
        <p class="fourth-p2">据悉，大赛优秀获奖项目除享受丰厚奖金，还将获得入驻梦想小镇、“融资直通车”等支持。</p>
        <div class="firth">
          <div><img src="@/assets/img/chuangke4.jpg" alt=""></div>
        </div>
        <p class="fourth-p2">决赛期间，围绕“青春建功新时代·创业追梦新征程”主题，大赛还设有“我的进化论”青创成果展、“青创会客厅”、“独角兽”闭门交流会、“遇见投资人”资本连连看等主题活动，让创业者们在比拼实力的同时，进一步提升创业素质、拓宽创业视野。</p>
        <p class="fourth-p2">共青团浙江省委、浙江省网信办、浙江省发改委、浙江省经信厅、浙江省科技厅、浙江省商务厅、杭州市人民政府、中国青少年新媒体协会承办了此次大赛，余杭区人民政府、未来科技城（海创园）管委会协办。团中央书记处书记傅振邦、浙江省副省长王文序等出席大赛总决赛并为获奖者颁奖。</p>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="https://mp.weixin.qq.com/s/snu_7bfdIUY8VAvHNd-LNA">https://mp.weixin.qq.com/s/snu_7bfdIUY8VAvHNd-LNA</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newsix"><img src="@/assets/img/jinling1.jpg" alt=""></a>
            <div class="middle">
              <a href="newsix" class="h4">2019(第三届)全球青年创新大会金领奖年度盛典</a>
              <span class="middle-p1">全球青年创新大会</span>
              <span class="middle-p2">金领奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/10/24 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newone"><img src="@/assets/img/865.jpg" alt=""></a>
            <div class="middle">
              <a href="newone" class="h4">海内外优质创新项目齐聚汕头首届创业之星大赛大奖出炉</a>
              <span class="middle-p1">汕头.创业之星比赛</span>
              <span class="middle-p2">三等奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/15 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/29 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
  text-align: center;
}
p{
    text-align: left;
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin: auto;
    padding: 30px;
    width: 1420px;
    overflow: hidden;
}
.left{
    width: 65%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
            margin-bottom: 1rem;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 100%;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
          display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
